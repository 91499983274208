<template>
  <div>
    <b-card-header class="d-block">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-card-title>{{ dataItem.title }}</b-card-title>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="text-right text-primary font-weight-bolder font-medium-3"
        >
          #{{ dataItem.onumber }}
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <div class="text-primary font-weight-bold">
            Teklif Tarihi
          </div>
          <div>
            {{ moment(dataItem.odate).format('LL') }}
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div class="text-primary font-weight-bold">
            Geçerlilik Tarihi
          </div>
          <div>
            {{ dataItem.vdate? moment(dataItem.vdate).format('LL') : '-' }}
          </div>
        </b-col>
        <b-col
          v-if="dataItem.id_offer_statuses > 1"
          cols="12"
          md="6"
          class="text-right"
        >
          <change-status />
        </b-col>
        <b-col cols="12">
          <div class="font-weight-bold font-medium-3 text-primary mt-2">
            Metin
          </div>
          <div v-html="dataItem.content" />
        </b-col>
        <b-col cols="12">
          <b-alert
            v-if="dataItem.notes"
            show
          >
            <div class="alert-body">
              <div class="font-weight-bold">
                Notlar
              </div>
              <div>
                {{ dataItem.notes }}
              </div>
            </div>
          </b-alert>
          <hr>
        </b-col>
        <!--        <template v-if="dataItem.id_offer_statuses === '4'">-->
        <!--          <b-col>-->
        <!--            <div class="border p-1 rounded text-center">-->
        <!--              <div class="font-weight-bold text-primary">-->
        <!--                Teklif Onaylayan-->
        <!--              </div>-->
        <!--              {{ dataItem.confirmed_user ? dataItem.confirmed_user : '-' }}-->
        <!--            </div>-->
        <!--          </b-col>-->
        <!--          <b-col>-->
        <!--            <div class="border p-1 rounded text-center">-->
        <!--              <div class="font-weight-bold text-primary">-->
        <!--                Teklif Onay Tarihi-->
        <!--              </div>-->
        <!--              {{ dataItem.confirmed? moment(dataItem.confirmed).format('llll') : '-' }}-->
        <!--            </div>-->
        <!--          </b-col>-->
        <!--        </template>-->
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardHeader, BCardTitle, BCardBody, BAlert,
} from 'bootstrap-vue'
import ChangeStatus from '@/views/Admin/Offers/elements/ChangeStatus.vue'

export default {
  name: 'OfferSummary',
  components: {
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAlert,
    ChangeStatus,
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
  },
}
</script>

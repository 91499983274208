<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      name="Teklif Durumu"
      rules="required"
    >
      <!--      <b-row>-->
      <!--        <b-col>-->
      <!--          <v-select-->
      <!--            id="id_offer_statuses"-->
      <!--            v-model="dataItem.id_offer_statuses"-->
      <!--            :options="dataList"-->
      <!--            label="title"-->
      <!--            :reduce="item => item.id"-->
      <!--            placeholder="Seçiniz"-->
      <!--            :disabled="dataItem.id_offer_statuses > 1"-->
      <!--          />-->
      <!--        </b-col>-->
      <!--        <b-col cols="auto">-->
      <!--          <b-button-->
      <!--            variant="primary"-->
      <!--            :disabled="!dataItem.id_offer_statuses || submitStatus || dataItem.id_offer_statuses > 1"-->
      <!--            @click="submitForm"-->
      <!--          >-->
      <!--            <FeatherIcon icon="SaveIcon" />-->
      <!--            Kaydet-->
      <!--          </b-button>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'OfferStatus',
  components: {
    BRow,
    BCol,
    BButton,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['offers/dataItem']
    },
    dataList() {
      return this.$store.getters['offerStatuses/dataList']
    },
    saveData() {
      return this.$store.getters['offers/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  mounted() {
    this.setDefaultStatus()
  },
  methods: {
    setDefaultStatus() {
      if (this.isNew) {
        this.dataItem.id_offer_statuses = '1'
      }
    },
    getData() {
      this.$store.dispatch('offerStatuses/getDataList', {
        select: [
          'offer_statuses.id AS id',
          'offer_statuses.title AS title',
        ],
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('offers/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
